<template>
  <div class="switch-wrap" @click="change">
    <div>
      <label class="switch radio">
        <template v-if="boolean">
          <input
            v-for="(input, index) in data"
            :key="index"
            v-model="model"
            type="radio"
            :name
            :value="(index !== 0)"
            :data-index="index"
            :checked="index === 0"
          >
        </template>
        <template v-else>
          <input
            v-for="(input, index) in data"
            :key="index"
            v-model="model"
            type="radio"
            :name
            :value="input.value"
            :data-index="index"
            :checked="index === 0"
          >
        </template>
        <span class="slider round" :data-emoji="emoji">
          <Icon v-if="symbol" :name="symbol" />
        </span>
      </label>
      {{ text }}
    </div>
    <div v-if="description" class="description">
      {{ description }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';

  const { name, data } = defineProps<{
    boolean?: true
    name: string
    data: {
      title: string
      icon?: string
      symbol?: string
      description?: string
      value?: string | boolean
    }[]
  }>()

  const model = defineModel<string | boolean>()

  const text = ref(data[0].title);
  const description = ref(data[0].description);
  const symbol = ref(data[0].symbol ?? '');
  const emoji = ref(data[0].icon ?? '');

  const change = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (target && target.dataset.index) {
      const index = +target.dataset.index as number;

      text.value = data[index].title
      description.value = data[index].description ?? ''
      symbol.value = data[index].symbol ?? '';
      emoji.value = data[index].icon ?? '';
    }
  }
</script>

<style scoped lang="scss">
  .switch-wrap {
    > div:first-child {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 0.75rem;
      font-weight: 600;
    }
    .description {
      margin-top: 0.5rem;
      font-size: 0.7rem;
      font-weight: 500;
      color: var(--grey);
    }
  }
  .switch.radio {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;

    & input {
      position: absolute;
      left: 0;
      top: 0;
      height: 24px;
      width: 40px;
      z-index: 2;
      opacity: 0;
      cursor: pointer;

      &:checked {
        z-index: 1;
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--grey);
      transition: all .3s ease;
      display: flex;
      justify-content: end;
      align-items: center;
      padding: 3px;
      > .icon {
        z-index: 1;
        opacity: 0;
        animation: fadeIn .2s ease-in-out forwards;
        animation-delay: .1s;
      }
      @keyframes fadeIn {
        to {
          opacity: 1;
        }
      }
    }

    .slider:before {
      content: attr(data-emoji);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: var(--white);
      transition: all .3s ease;
    }

    input:checked ~ .slider {
      background-color: var(--green);

    }

    input ~ input:checked ~ .slider {
      background-color: var(--primary-color-light);
    }

    input ~ input:checked ~ .slider:before {
      transform: translateX(16px);
    }

    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
</style>
