<template>
  <div class="captcha-setups">
    <Captcha ref="captcha" :init-props :editable="true">
      <div v-if="greetingPageState()" class="message-content">
        <EditableTextWithStatic :name="'greeting_text'" :name2="'greeting_text_p2'" @save="save">
          <span class="user-link">{{ setup.data.user }}</span>
        </EditableTextWithStatic>
        <div class="welcome-text">
          <EditableText :name="'welcome_text'" @save="save" />
        </div>
        <Button>
          <EditableText :name="'message_button'" @save="save" />
        </Button>
      </div>
    </Captcha>

    <div class="side-bar">
      <ButtonSmall :class="{'active' : captchaPageState()}" @click="changePage('captcha')">
        <Icon :name="'like'" />
      </ButtonSmall>
      <ButtonSmall :class="{'active' : greetingPageState()}" @click="changePage('greeting')">
        <Icon :name="'warning'" />
      </ButtonSmall>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, watch } from  'vue';
  import { InitControllerData } from '@/types/controller';
  import Captcha from '@/view/pages/captchas/Captcha.vue';
  import { useControllerStore } from '@/stores/controller';
  import { storeToRefs } from 'pinia';
  import { z } from '@/composables/zod-i18n';
  import { useValidatorStore } from '@/stores/validator';

  const { setup } = storeToRefs(useControllerStore());
  const initProps = defineProps<InitControllerData>();

  const pageState = ref('captcha');
  const captcha = ref();

  const greetingPageState = () => pageState.value == 'greeting'
  const captchaPageState = () => pageState.value == 'captcha'

  const { setFormSchema } = useValidatorStore()
  const { formDefault } = storeToRefs(useValidatorStore())

  const formSchema = () => {
    return z.object({
      greeting_text: z.string().max(10),
      greeting_text_p2: z.string().max(10),
      welcome_text: z.string().max(140),
      message_button: z.string().max(20),
      not_robot: z.string().max(20),
      submit: z.string().max(15),
    })
  };

  type FormSchema = z.infer<ReturnType<typeof formSchema>>;

  watch(() => setup.value.data, () => {
    const formData:FormSchema = {
      ...setup.value.data.greeting,
      not_robot: setup.value.data.captcha.not_robot,
      submit: setup.value.data.captcha.submit
    }
    formDefault.value = setup.value.data.default;
    setFormSchema(formSchema(), formData)
  }, { immediate: false });

  const changePage = (page: string) => {
    setup.value.data[pageState.value] = setup.value.locale;
    setup.value.locale = setup.value.data[page]
    pageState.value = page;
  }

  const save = (name: string) => captcha.value?.save(name);
</script>

<style scoped lang="scss">
  div {
    :deep(.close-app) {
      pointer-events: none;
      cursor: pointer;
    }
    :deep(.captcha) {
      .center {
        pointer-events: none;
        cursor: pointer;
      }
    }
    .side-bar {
      --btn-active: #353E88;
      position: fixed;
      left: 20px;
      top: 15%;
      display: flex;
      flex-direction: column;
      gap: var(--space-half);
      width: 48px;
      height: 160px;
    }
  }
  .message-content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    gap: var(--space);
  }
  .user-link {
    color: var(--primary-color);
    font-weight: 600;
    cursor: pointer;
  }
</style>
