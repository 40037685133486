<template>
  <Vue3Lottie
    :animation-data
    :height="200"
    :width="200"
    :loop="false"
    :pause-animation="animation"
    :class="animationName"
    @click="changeAnimation"
  />
</template>

<script setup lang="ts">
  import { ref, onMounted } from 'vue'
  import { Vue3Lottie } from 'vue3-lottie'
  import { useWebAppTheme } from 'vue-tg'

  const { animationName } = defineProps<{animationName: string}>()

  const animation = ref(false);
  const animationData = ref(null);

  const changeAnimation = () => animation.value = !animation.value;

  onMounted(async () => {
    const currentTheme = useWebAppTheme().colorScheme.value;
    const importedData  = await import(`./schemas/${animationName}-${currentTheme}.json`);

    animationData.value = structuredClone(importedData.default);
  });
</script>

<style scoped lang="scss">
  .popper {
    transform: scale(1.5);
    transform-origin: center;
  }
</style>
