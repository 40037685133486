<template>
  <div>
    <Captcha :init-props :drop-icon-state="true" />
    <div class="side-bar">
      <ButtonSmall :class="{'active' : !setup.locale?.message}" @click="changePage('captcha')">
        <Icon :name="'like'" />
      </ButtonSmall>
      <ButtonSmall :class="{'active' : setup.locale?.warning}" @click="changePage('warning')">
        <Icon :name="'warning'" />
      </ButtonSmall>
      <ButtonSmall :class="{'active' : setup.locale?.error}" @click="changePage('error')">
        <Icon :name="'robot'" />
      </ButtonSmall>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { InitControllerData } from '@/types/controller';
  import Captcha from '@/view/pages/captchas/Captcha.vue';
  import { useControllerStore } from '@/stores/controller';
  import { storeToRefs } from 'pinia';

  const { setup } = storeToRefs(useControllerStore());

  const initProps = defineProps<InitControllerData>();

  const changePage = async (page: string) => setup.value.locale = setup.value.data[page]
</script>

<style scoped lang="scss">
  div {
    :deep(.close-app) {
      pointer-events: none;
      cursor: pointer;
    }
    .side-bar {
      --btn-active: #353E88;
      position: fixed;
      left: 20px;
      top: 15%;
      display: flex;
      flex-direction: column;
      gap: var(--space-half);
      width: 48px;
      height: 160px;
    }
  }
</style>
