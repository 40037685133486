<template>
  <Captcha :init-props />
</template>

<script setup lang="ts">
    import Captcha from '@/view/pages/captchas/Captcha.vue';
    import { InitControllerData } from '@/types/controller';

    const initProps = defineProps<InitControllerData>()
</script>

<style scoped lang="scss"></style>
