<template>
  <div class="btn-group">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
  .btn-group {
    --btn-even-bg-color: var(--white);
    --btn-even-text-color: var(--primary-color);

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 3rem;

    :deep(button:nth-child(odd)) {
      background-color: var(--primary-color);
      color: var(--white);
    }

    :deep(button:nth-child(even)) {
      background-color: var(--btn-even-bg-color);
      color: var(--btn-even-text-color);
      border: var(--btn-border);
    }
  }
</style>
