<template>
  <div class="info">
    <Image :src="img" :alt />
    <h2>{{ title }}</h2>
  </div>
  <div class="line" />
</template>

<script setup lang="ts">
  import { computed } from 'vue';

  const { img, title } = defineProps<{
    img: string;
    title: string;
  }>();

  const alt = computed(() => `${title} - avatar`)
</script>

<style scoped lang="scss">
  .info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--space);
    height: 44px;
    padding: 9px;

    img {
      width: 30px;
      height: 30px;
    }
    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
      color: var(--hyper-text-color);
    }
  }
  .line {
    display: block;
    height: 1px;
    max-width: 200px;
    width: 100%;
    margin: 8px 0;
    background-color: var(--text-color);
  }
</style>
