<template>
  <Main :init-props>
    <ButtonBack :href="'/payments'" />
    <Payments class="primes">
      <template #title>
        <h1>{{ setup.locale.h1 }}</h1>
      </template>
      <template #details>
        <Section>
          <h4>{{ setup.locale.details.title }}</h4>
          <div>
            {{ setup.locale.details.description }}
            <Link href="/instructions" :data="{ scrollTo: { selector: '#wardy-prime', active: true } }">
              {{ setup.locale.details.link_text }}
            </Link>
          </div>
        </Section>
      </template>
      <template #payment-cards>
        <CardContainer class="payment" :data="setup.locale.methods_data">
          <Card
            v-for="(item, index) in setup.locale.methods"
            :key="item.id"
            v-model.number="form.payment"
            :item
            :index
            name="payment"
            :data-label="(item.id === form.payment) ? dataLabel : ''"
            @change="changePayment"
          />
        </CardContainer>
      </template>
      <template #amount-cards>
        <CardContainer class="amount" :data="setup.locale.subscribe_data">
          <Card
            v-for="(item, index) in setup.data[form.payment]"
            :key="item.id"
            v-model.number="form.amount"
            :item
            :index
            name="amount"
            @change="changeAmount"
          />
        </CardContainer>
      </template>
    </Payments>
  </Main>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue';
  import { storeToRefs } from 'pinia';
  import { useControllerStore } from '@/stores/controller';

  import type { InitControllerData } from '@/types/controller';

  import { useValidatorStore } from '@/stores/validator';
  import { z } from '@/composables/zod-i18n';

  const { setup } = storeToRefs(useControllerStore())
  const initProps = defineProps<InitControllerData>()

  const dataLabel = ref('')

  const { setFormSchema } = useValidatorStore()
  const { form } = storeToRefs(useValidatorStore())

  const formSchema = () => {
    return z.object({
      payment: z.enum(['coins', 'stars', 'cards']).default('coins'),
      amount: z.number().int(),
    })
  };

  type FormSchema = z.infer<ReturnType<typeof formSchema>>;

  const formData:FormSchema = { payment: 'coins', amount: 10 }

  watch(() => setup.value.data?.prices, (newPrices) => {
    if (!newPrices) return;
    const firstKey = Object.keys(newPrices[form.value.payment])?.[0];
    form.value.amount = +setup.value.data.coins[0].id
    dataLabel.value = newPrices[form.value.payment][firstKey];
  })

  const changePayment = ( { target }: { target: HTMLInputElement } ) => form.value.payment = target.value;

  const changeAmount = ( { target }: { target: HTMLInputElement } ) => {
    dataLabel.value = setup.value.data.prices[form.value.payment][target.value]
  };

  setFormSchema(formSchema(), formData)
</script>

<style scoped lang="scss">
  .primes {
    h1 {
      margin-bottom: 0;
    }
    .amount {
      order: 1;
      .card {
        :deep(h5) {
          margin-top: 0;
        }

        :deep(.description) {
          margin-top: 0;
          font-size: 18px;
        }
      }
    }
    .payment {
      order: 2;

      label {
        position: relative;
        padding: 14px;
        :deep(.description) {
          margin-top: 0;
        }
        &[data-label]:before {
          content: attr(data-label);
          position: absolute;
          top: var(--space);
          right: var(--space);
          font-size: 28px;
          color: #ff0000;
          font-weight: 700;
        }
      }
    }
  }
</style>
