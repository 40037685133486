<template>
  <Button>
    <slot />
  </Button>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
  [data-theme="light"] button {
    --btn-active: #A6AFFF;
    --btn-color: #FAFAFA;
    --gradient: linear-gradient(197.68deg, rgba(80, 97, 255, 0.46) -105.35%, #F9F9F9 51.56%, rgba(80, 97, 255, 0.46) 183.28%);
  }
  button {
    --gradient: linear-gradient(187.94deg, rgba(155, 162, 224, 0.46) 10.08%, #1F1E6B 42.94%, rgba(38, 51, 168, 0.46) 90.22%);
    --btn-color: #1E2454;
    --btn-active: #353E88;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    width: 47px;
    background-color: var(--btn-color);
    border-radius: var(--radius-half);
    &:active {
      background-color: var(--btn-active);
    }
    &::after {
      content: '';
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background: var(--gradient);
      box-shadow: 0px 2px 6.8px 0px rgba(0, 0, 0, 0.09);
      z-index: -1;
      border-radius: var(--radius-half);
    }
    :deep(svg) {
      height: 28px;
      width: 28px;
    }
  }
  .active {
    background-color: var(--btn-active);
  }
</style>
