<template>
  <input
    v-model="model"
    :type
    :name
    :placeholder
    @input="useFieldValidator(name)"
    @focus="clearError(name)"
    @blur="clearError(name)"
  >
  <InputError :name />
</template>

<script setup lang="ts">
  import { useValidatorStore } from '@/stores/validator'

  const { useFieldValidator, clearError } = useValidatorStore()

  const { name, type = 'text', placeholder = '' } = defineProps<{
    name: string
    type?: string
    placeholder?: string
  }>()

  const model = defineModel<string | number>()
</script>

<style scoped lang="scss">
  input {
    width: 100%;
    height: 46px;
    padding: 0 var(--space-half);
    border: var(--input-border);
    border-radius: var(--radius-half);
    font-size: var(--input-font-size);
    font-weight: 400;
    color: var(--input-text-color);
    background-color: var(--input-bg-color);
    outline: none;
    transition: all .3s ease;

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }


    &:focus, &:hover {
      border-color: var(--placeholder-color);
    }

    &::placeholder {
      color: var(--placeholder-color);
    }

    &:disabled {
      background-color: var(--input-disabled-bg-color);
      border-color: var(--input-disabled-border-color);
      cursor: not-allowed;
    }

    &:has( + .error) {
      border-color: var(--input-error-color);
      box-shadow: 0 0 22.3px 2px rgba(255, 0, 0, 0.2);
    }
  }
</style>
