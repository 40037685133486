<template>
  <Teleport defer to="body">
    <div v-if="open" class="notification">
      <div>{{ message }}</div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { useNotificationStore } from '@/stores/notification'

  const { open, message } = storeToRefs(useNotificationStore())
</script>

<style scoped lans="scss">
  .notification {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    width: 100%;
    height: 100dvh;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 60px;
      width: 300px;
      padding: .5rem;
      border-radius: var(--radius-half);
      background-color: rgba(26, 24, 24, 0.8);
      font-family: 'Roboto';
      font-size: 1.5rem;
      text-align: center;
      color: var(--white);
    }
  }
  body:has(.captcha-setups) {
    .notification {
      height: auto ;
      div {
        background-color: rgba(0, 0, 0, 0.5) ;
        width: 100% ;
        padding: 1rem ;
        font-size: 1rem ;
      }
    }
  }
</style>
