<template>
  <div class="decorator-two-side-line">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
  .decorator-two-side-line {
    border-left: 2px solid;
    border-right: 2px solid;
    border-color: var(--bg-preset-color-40);
    padding: 0 5px;
  }
</style>
