<template>
  <div class="tabs-header">
    <TabButton
      v-for="tab in tabs"
      :key="tab.target"
      :tab
      :class="{ active: tab.target === model }"
      @click="toggleTab(tab.target)"
    />
    <slot />
  </div>
</template>

<script setup lang="ts">
  import type { Tab } from '@/types/tabs'

  defineProps<{ tabs: Tab[] }>()

  const model = defineModel({ type: String })

  const toggleTab = (name: string) => model.value = name;
</script>

<style scoped lang="scss">
  .tabs-header {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 2rem;
  }
</style>
