<template>
  <div>
    <span :contenteditable="true" @input="update" @blur="onSave">{{ form[props.name] }}</span>
    <slot />
    <span :contenteditable="true" @input="updateSecond" @blur="onSave">{{ form[props.name2] }}</span>
    <button class="btn" @click.prevent="onSave">
      ✓
    </button>
    <button class="btn" @click.prevent="reset">
      ⟳
    </button>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { useValidatorStore } from '@/stores/validator';

  const { form, formDefault } = storeToRefs(useValidatorStore());

  interface EditableTextProps {
    name: string;
    name2: string;
  }
  const props = defineProps<EditableTextProps>();

  const emit = defineEmits<{
    (e: 'save', name: string): void;
  }>();

  const onSave = () =>  {
    emit('save', props.name);
    emit('save', props.name2);
  }
  const reset = () =>  {
    form.value[props.name]  = formDefault.value[props.name];
    emit('save', props.name);
    form.value[props.name2]  = formDefault.value[props.name2];
    emit('save', props.name2);
  }
  const update = (event: any) => form.value[props.name] = event.target.textContent;
  const updateSecond = (event: any) => form.value[props.name2] = event.target.textContent;
</script>

<style scoped lang="scss">
  span {
    word-wrap: break-word;
  }
  .btn {
    background: transparent;
    border: none;
    cursor: pointer;
    pointer-events: auto;
    margin: 0;
    padding: 0;

    &:nth-last-of-type(2) {
      color: #48f542;
    }

    &:last-of-type {
      color: #f58442;
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  div {
    display: block;
    white-space: pre-wrap;
  }
</style>
