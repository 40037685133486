<template>
  <div id="loading">
    <svg viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M63 31.5C63 48.897 48.897 63 31.5 63C14.103 63 0 48.897 0 31.5C0 14.103 14.103 0 31.5 0C48.897 0 63 14.103 63 31.5ZM6.58117 31.5C6.58117 45.2623 17.7377 56.4188 31.5 56.4188C45.2623 56.4188 56.4188 45.2623 56.4188 31.5C56.4188 17.7377 45.2623 6.58117 31.5 6.58117C17.7377 6.58117 6.58117 17.7377 6.58117 31.5Z" fill="url(#paint0_linear_1312_15)" />
      <defs>
        <linearGradient
          id="paint0_linear_1312_15"
          x1="63"
          y1="26.0762"
          x2="31.5"
          y2="63"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D9D9D9" />
          <stop offset="1" stop-color="#5061FF" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
  #loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 106px;
    svg {
      width: 63px;
      height: 63px;
      margin: 0;
      animation: rotating 2s linear infinite;
    }
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
