<template>
  <Main :init-props>
    <Payments>
      <template #title>
        <h1>{{ setup.locale.h1 }}</h1>
      </template>
      <template #profile>
        <ProfileInfo
          v-if="setup.data?.group?.photo"
          :img="setup.data.group.photo"
          :title="setup.data.group.title"
        />
      </template>
      <template #general>
        <Section :data="setup.locale" />
      </template>
      <template #payment-cards>
        <CardContainer :data="setup.locale.methods_data">
          <Card
            v-for="(item, index) in setup.locale.methods"
            :key="item.id"
            v-model="form.payment"
            :item
            :index
            name="payment"
            @change="changePayment"
          />
        </CardContainer>
      </template>
      <template #amount>
        <Section :data="setup.locale.input">
          <InputGroup>
            <Input v-model="form.amount" type="number" name="amount" :placeholder />
          </InputGroup>
        </Section>
      </template>
      <template #comment>
        <Section>
          <h4>{{ setup.locale.comment }}</h4>
          <InputGroup>
            <Input v-model="form.comment" name="comment" />
          </InputGroup>
        </Section>
      </template>
      <template #anonymous>
        <Section v-if="setup.locale.anonymous" class="switch">
          <SwitchRadioReverse v-model="form.anonymous" name="anonymous" :boolean="true" :data="setup.locale.anonymous" />
        </Section>
      </template>
    </Payments>
  </Main>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue';
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'
  import { useValidatorStore } from '@/stores/validator'

  import { z } from '@/composables/zod-i18n';
  import validatorSchema from '@/config/validatorSchema.json'

  import type { InitControllerData } from '@/types/controller'

  const { setup } = storeToRefs(useControllerStore())
  const initProps = defineProps<InitControllerData>()

  const { setFormSchema } = useValidatorStore()
  const { form } = storeToRefs(useValidatorStore())

  const min = ref('')
  const placeholder = ref('')

  const formSchema = () => {
    return z.object({
      payment: z.enum(['coins', 'tokens']).default('coins'),
      amount: z.number().min(+min.value).max(validatorSchema.amount.max).int(),
      comment: z.string().max(validatorSchema.comment.max),
      anonymous: z.boolean(),
    })
  };

  type FormSchema = z.infer<ReturnType<typeof formSchema>>;

  const formData:FormSchema = { payment: 'coins', amount: validatorSchema.donates.min , comment: '', anonymous: false }

  watch(() => setup.value.locale, (newLocale) => {
    if (newLocale.input) {
      min.value = newLocale.input.coins.min
      placeholder.value = newLocale.input.coins.placeholder;
      setFormSchema(formSchema(), formData)
    }
  }, { immediate: false });

  const changePayment = ({ target }: { target: HTMLInputElement }) => {
    min.value = setup.value.locale.input[target.value].min
    placeholder.value = setup.value.locale.input[target.value].placeholder
    setFormSchema(formSchema(), formData)
  }
</script>

<style scoped lang="scss">
  h1:first-child {
    text-align: center;
    margin: 1rem 0 2rem;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
  }
  :deep(.section) {
    padding: 0;
     h4 {
       margin: 0 0 var(--space-half) 0;
     }
     background-color: var(--body-bg-color);
    &:first-of-type {
      > div:first-child {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        font-size: 12px;
        gap: 4px;
        > div {
          font-size: 12px;
          color: #6B6B6B;
        }
      }
    }
  }
</style>
