import { storeToRefs } from 'pinia'
import axios from 'axios';

import { useUserStore } from '@/stores/user'
import { useControllerStore } from '@/stores/controller';
import { useNotificationStore } from '@/stores/notification'

import { convertObjectValueBooleanStringToBoolean } from '@/composables/objectConvertors'

export const api = () => {
  const { token } = storeToRefs(useControllerStore())
  const fetchData = async (url: string, initData: string, options: object = {}) => {
    const { getUserData } = useUserStore()
    const userData = await getUserData(initData);

    try {
      const { data } = await axios.post(url,
        {
          ...userData,
          ...(initData && { initData }),
          options: {
            ...convertObjectValueBooleanStringToBoolean(options)
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${token.value}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (data.locale?.error) {
        console.error('Error fetching data:', data.locale.message);
      }

      return data;
    } catch(error) {
      console.error('Error fetching data:', error);
    }
  }

  const deleteData = async (url: string, id: number) => {
    const { setNotification } = useNotificationStore()

    try {
      const { data } = await axios.delete(`${url}/${id}`, {
        headers: {
          'Authorization': `Bearer ${token.value}`
        }
      });

      if (data.locale?.error) {
        console.error('Error deleting data:', data.locale.message);
      }

      if (data.notification) {
        setNotification(data.notification)
      }
    } catch (error) {
      console.error('Error deleting data:', error);
      throw error;
    }
  };

  return {
    fetchData,
    deleteData
  }
}
