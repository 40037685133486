<template>
  <Confirmation v-if="setup.locale?.data?.type === 'confirmation'" />
  <MessageSystem v-else-if="setup.locale.warning || setup.locale.error" />
  <Message v-else />
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'

  const { setup } = storeToRefs(useControllerStore())
</script>

<style scoped></style>
