<template>
  <Main :init-props>
    <div class="roleplay">
      <h1>{{ setup.locale.title }}</h1>
      <ProfileInfo :img="setup.data.group_info.photo" :title="setup.data.group_info.title" />
      <TabsHeader v-model="activeTab" :tabs="setup.locale.tabs" />
      <TabContent :id="setup.locale.tab_commands.id" :active-tab>
        <TabCommands />
      </TabContent>
      <TabContent :id="setup.locale.tab_add.id" :active-tab>
        <Form>
          <h3>{{ setup.locale.tab_add.trigger.title }}</h3>
          <InputGroup>
            <Textarea
              v-model="form.trigger"
              name="trigger"
              :placeholder="setup.locale.tab_add.trigger.placeholders[form.type]"
            />
          </InputGroup>
          <SwitchContainer>
            <SwitchRadio v-model="form.type" name="type" :data="setup.locale.tab_add.trigger.switch" />
          </SwitchContainer>
          <h3>{{ setup.locale.tab_add.response.title }}</h3>
          <p class="example" v-html="setup.locale.tab_add.trigger.example" />
          <InputGroup>
            <Textarea v-model="form.format" name="format" :placeholder="setup.locale.tab_add.response.placeholder" />
          </InputGroup>
          <h3>{{ setup.locale.tab_add.kind.title }}</h3>
          <SwitchContainer>
            <SwitchRadio v-model="form.kind" name="kind" :data="setup.locale.tab_add.kind.switch" />
          </SwitchContainer>
          <h3>{{ setup.locale.tab_add.privacy.title }}</h3>
          <SwitchContainer>
            <SwitchRadio
              v-model="form.private"
              name="private"
              :boolean="true"
              :data="setup.locale.tab_add.privacy.private"
            />
            <SwitchRadio
              v-model="form.citizenship"
              name="citizenship"
              :boolean="true"
              :data="setup.locale.tab_add.privacy.citizenship"
            />
          </SwitchContainer>
        </Form>
      </TabContent>
    </div>
    <ButtonGroup v-show="activeTab === setup.locale.tab_add.id">
      <Button @click.prevent="submitFormData">
        {{ setup.locale.buttons.create }}
      </Button>
      <ButtonCloseApp>{{ setup.locale.buttons.close }}</ButtonCloseApp>
    </ButtonGroup>
  </Main>
</template>

<script lang="ts" setup>
  import { ref } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'
  import { useNotificationStore } from '@/stores/notification'
  import { useValidatorStore } from '@/stores/validator'

  import { z } from '@/composables/zod-i18n'
  import { validateEmojiLength, validateOnlyEmoji } from '@/composables/validators/emoji'
  import validatorSchema from '@/config/validatorSchema.json';

  import TabCommands from '@/view/pages/roleplays/TabCommands.vue'

  import type { InitControllerData } from '@/types/controller'

  const { setup } = storeToRefs(useControllerStore())
  const { setServiceData } = useControllerStore()
  const { setNotification } = useNotificationStore()
  const initProps = defineProps<InitControllerData>()

  const { useFormValidator, setFormSchema } = useValidatorStore()
  const { validator, form } = storeToRefs(useValidatorStore())
  const activeTab = ref('add')

  const formSchema = () => {
    return z.object({
      trigger: z.string().superRefine((value, ctx) => {
        const result = createTriggerSchema(form.value.type).safeParse(value)

        if (!result.success) {
          ctx.addIssue(result.error.issues[0])
        }

        return result.success
      }),
      type: z.enum(['text', 'emoji']).default('text'),
      format: z.string().min(2).max(150)
          .refine((value) => value.match(new RegExp(validatorSchema.regexp.users)), {params: {i18n: 'error_users_interaction'}}),
      kind: z.enum(['regular', 'nsfw']).default('regular'),
      private: z.boolean(),
      citizenship: z.boolean()
    })
  }

  type FormSchema = z.infer<ReturnType<typeof formSchema>>;

  const formData:FormSchema = {
    trigger: '',
    type: 'text',
    format: '',
    kind: 'regular',
    private: false,
    citizenship: false
  }

  setFormSchema(formSchema(), formData)

  const createTriggerSchema = (type: 'text' | 'emoji') => {
    let schema;

    if (type === 'text') {
      schema = z.string().min(2).max(15)
    } else if (type === 'emoji') {
      schema = z.string().refine((value) => validateOnlyEmoji(value), { params: { i18n: 'error_only_emoji' } })
    }

    return schema!.refine((value) => validateEmojiLength(value), { params: { i18n: 'error_one_emoji' } })
  }

  const submitFormData = async () => {
    useFormValidator()

    if (validator.value.valid) {
      await setServiceData(form.value, '')

      await queueMicrotask(() => {
        setNotification(setup.value.data.notification)
        form.value.trigger = ''
        form.value.format = ''
      });
    }
  }
</script>

<style lang="scss">
  .roleplay {
    h1 {
      margin: 1rem 0 2rem;
      font-family: 'Roboto';
      font-size: 24px;
      font-weight: 700;
      text-align: center;
    }
    .example {
      color: #6B6B6B;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1rem;
      code {
        padding: 0;
        color: var(--hyper-text-color);
        font-size: 0.75rem;
        font-weight: 500;
        background-color: transparent;
      }
    }
    textarea[name="responseText"] {
      min-height: 80px;
    }
  }
</style>
