<template>
  <div class="commands">
    <div v-for="command in commands" :key="command.id">
      <code>{{ command.trigger }}</code>
      <div v-if="command.types" class="types">
        <div v-for="(_, key) in command.types" :key>
          {{ setup.locale.types[key] }}
        </div>
      </div>
      <Button @click="deleteCommand(command.id)">
        <Icon name="delete" />
      </Button>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, watch } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useWebAppPopup } from 'vue-tg'
  import { api } from '@/composables/api'

  import { useControllerStore } from '@/stores/controller'

  interface TypeCommands {
    types: {
      nsfw?: boolean
      private?: boolean
      citizenship?: boolean
    }
  }

  interface Command extends TypeCommands {
    id: number
    trigger: string
  }

  const { setup } = storeToRefs(useControllerStore())

  const commands = ref([...setup.value.data.commands as Command[]])

  watch(() => setup.value.data.commands, (newData: Command[]) => commands.value = newData)

  const getCommand = (id: number): Command => commands.value.filter((command) => command.id == id)[0]

  const deleteCommand = (id: number) => {
    const params = {
      title: getCommand(id).trigger,
      ...setup.value.locale.tab_commands.popup
    }

    useWebAppPopup().showPopup(params, async (buttonId: string) => {
        if (buttonId === 'delete') {
            await api().deleteData('roleplays', id)
            commands.value = commands.value.filter((command) => command.id !== id)
        }
      }
    )
  }
</script>

<style scoped lang="scss">
  .commands {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space);

    & > div {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--space-half);
      padding: 3px;
      background-color: var(--bg-preset-color-20);
      border-radius: var(--radius-half);
    }

    .types {
      position: absolute;
      top: -40%;
      right: 40px;
      display: flex;
      pointer-events: none;
    }

    code {
      max-width: 120px;
      width: 100%;
      background-color: transparent;
      color: var(--text-color);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    button {
      display: inline-flex;
      align-items: center;
      width: auto;
      height: 20px;
      margin: unset;
      background-color: transparent;
      border-radius: var(--radius-half);

      &:focus, &:active {
        box-shadow: none;
      }
    }
  }
</style>
