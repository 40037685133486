import { ref } from 'vue'
import { defineStore } from 'pinia'
import { z } from '@/composables/zod-i18n'
import { debounce } from '@/composables/debounce'
import settings from '@/config/settings.json';

export const useValidatorStore = defineStore('validator', () => {
  type FormErrors<T> = {
    [K in keyof T]?: { _errors: string[] } | {};
  } & {
    [key: string]: { _errors: string[] } | {} | undefined;
  };

  const validator = ref<{
    errors: FormErrors<Record<string, any>>;
    valid: boolean;
  }>({
    errors: {},
    valid: false
  });

  const form = ref<Record<string, any>>({});
  const formDefault = ref<Record<string, any>>({});
  const formSchema = ref<z.ZodSchema<any> | null>(null);

  const setFormSchema = <T extends Record<string, any>>(schema: z.ZodSchema<T>, formData: T) => {
    formSchema.value = schema;
    form.value = formData;
  };

  const clearError = (fieldName: any, immediately: boolean = true) => {
    function removeError() {
      if (validator.value.errors && fieldName in validator.value.errors) {
        delete validator.value.errors[fieldName as keyof typeof validator.value.errors]
      }
    }

    if (immediately) {
      removeError()
    } else {
      setTimeout(() => removeError(), settings.validator.error.remove_delay)
    }
  }

  const useFormValidator = () => {
    const validSchema = formSchema.value?.safeParse(form.value)

    if (validSchema) {
      validator.value.valid = validSchema.success;
      validator.value.errors = (!validSchema.success) ? validSchema.error.format() : {};
    }
  }

  const useFieldValidator = debounce((fieldName: string) => {
    const fieldSchema = (formSchema.value as z.ZodObject<any>)?.shape[fieldName]
    const validSchema = fieldSchema.safeParse(form.value[fieldName])

    validator.value.valid = validSchema.success;

    if (validSchema.success) {
      clearError(fieldName)
    } else {
      validator.value.errors = { ...validator.value.errors, [fieldName]: validSchema.error.format() };
    }
}, settings.validator.error.display_delay)

  return {
    form,
    validator,
    formDefault,
    useFormValidator,
    useFieldValidator,
    setFormSchema,
    clearError
  }
})
