<template>
  <Main :init-props>
    <ButtonCloseApp />
  </Main>
</template>

<script lang="ts" setup>
  import type { InitControllerData } from '@/types/controller';

  const initProps = defineProps<InitControllerData>()
</script>

<style lang="scss"></style>
