<template>
  <BaseNotification />
</template>

<script lang="ts" setup>
  import { onMounted } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'
  import { setThemeType, setCustomColorTheme } from '@/composables/templateSettings';

  const { setup } = storeToRefs(useControllerStore())

  const props = defineProps<{
    controller: string;
    locale: object;
    base_locale: object;
  }>()

  Object.assign(setup.value, props)
  setup.value.locale.error = true

  onMounted(() => {
    setThemeType();
    setCustomColorTheme();
  });
</script>

<style scoped lang="scss">
  .message {
    gap: 80px;
    :deep(.main-body) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      flex: none;
      gap: 80px;
      padding-bottom: 70px;
    }
    :deep(.content) {
      --section-bg-color: var(--message-bg-color);
      display: flex;
      flex-direction: column;
      align-items: center ;
      justify-content: center;
      text-align: center;
      color: var(--message-text-color);
      background-color: var(--message-bg-color);
    }
    :deep(.image) {
      flex: none;
    }
  }
</style>
