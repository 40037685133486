<template>
  <Main :init-props>
    <div class="turbo">
      <ProfileInfo :img="setup.data.photo" :title="setup.data.title" />
      <p> {{ setup.locale.info.text }} </p>
      <div class="list">
        <ul>
          <li
            v-for="(item, index) in setup.locale.info.lists"
            :key="index"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="actions">
        <Form @submit.prevent="submitFormData">
          <label v-for="(item, index) in setup.data.prices.turbo" :key="index">
            <input
              v-model="form.amount"
              type="radio"
              :value="+item.value"
              name="amount"
            >
            {{ item.description }}
          </label>
          <Button>{{ setup.base_locale.buy }}</Button>
        </Form>
        <ButtonCloseApp>{{ setup.base_locale.back }}</ButtonCloseApp>
      </div>
    </div>
  </Main>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia';
  import { z } from '@/composables/zod-i18n';

  import { useControllerStore } from '@/stores/controller';
  import { usePaymentStore } from '@/stores/payment'
  import { useValidatorStore } from '@/stores/validator';

  import type { InitControllerData } from '@/types/controller';
  import {watch} from "vue";

  const { setup } = storeToRefs(useControllerStore())
  const initProps = defineProps<InitControllerData>()

  const { setPaymentData } = usePaymentStore()
  const { setServiceData } = useControllerStore()

  const { useFormValidator, setFormSchema } = useValidatorStore()
  const { validator, form } = storeToRefs(useValidatorStore())

  const formSchema = () => {
    return z.object({
      payment: z.literal('turbo'),
      amount: z.number().int(),
    })
  };

  type FormSchema = z.infer<ReturnType<typeof formSchema>>;

  const formData:FormSchema = { payment: 'turbo', amount: 30 }

  setFormSchema(formSchema(), formData)

  watch(() => setup.value.data, (newData) => {
    if (newData.prices) {
      form.value.amount = +newData.prices.turbo[0].value;
    }
  }, { immediate: false });

  const submitFormData = () => {
    useFormValidator()

    if (validator.value.valid) {
      setPaymentData(form.value)
      setServiceData(form.value, '')
      setup.value.loaded = false
    }
  }
</script>

<style scoped lang="scss">
.turbo {
  .list {
  display: flex;
  justify-content: center;
    ul {
      padding: 0;
      list-style: none;
      line-height: 2rem;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--space-half);
    width: 100%;
    margin: 0 var(--space) var(--space);

    label {
      max-width: 280px;
      width: 100%;
      padding: var(--space-half);
      border-radius: var(--radius-half);
      border: 4px solid var(--grey);
      text-align: center;
      &:has(input:checked) {
        border: 4px solid var(--danger-color);
      }
    }

    input {
      display: none
    }

    button {
      margin-top: var(--space-half);
    }
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space);
    margin-top: var(--space);
  }
}
</style>
