<template>
  <Image src="logo.webp" alt="logo" :style />
</template>

<script setup lang="ts">
  import { computed } from 'vue';

  const { width = '200', height = '200' } = defineProps<{
    width?: string | number
    height?: string | number
  }>()

  const style = computed(() => ({ maxWidth: `${width}px`, maxHeight: `${height}px` }))
</script>

<style lang="scss"></style>
