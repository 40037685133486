<template>
  <Main :init-props>
    <div class="captcha">
      <Fingerprint :state="iconState" />
      <Loading v-if="loading" />
      <slot v-else>
        <form @submit.prevent="submitFormData">
          <div v-if="setup.locale.verified">
            <label class="verified">{{ setup.locale.verified }}</label>
            <ButtonCloseApp />
          </div>
          <div v-else>
            <div class="label-group">
              <Checkbox v-if="setup.locale.not_robot" id="not-robot" name="notRobot" required />
              <label for="not-robot">
                <EditableText
                  v-if="setup.data.editable"
                  :name="'not_robot'"
                  @save="save"
                />
                <span v-else>{{ setup.locale.not_robot }}</span>
              </label>
            </div>
            <Button>
              <EditableText
                v-if="setup.data.editable"
                :name="'submit'"
                @save="save"
              />
              <span v-else>{{ setup.locale.submit }}</span>
            </Button>
          </div>
        </form>
      </slot>
    </div>
  </Main>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { storeToRefs } from 'pinia';
  import { useControllerStore } from '@/stores/controller';
  import { useWebAppHapticFeedback } from 'vue-tg';
  import type { InitControllerData } from '@/types/controller';
  import { useValidatorStore } from '@/stores/validator';
  import { useNotificationStore } from '@/stores/notification';

  const { setNotification } = useNotificationStore();
  const { setServiceData } = useControllerStore()
  const { setup } = storeToRefs(useControllerStore())
  const { notificationOccurred } = useWebAppHapticFeedback()
  const { form, validator } = storeToRefs(useValidatorStore())
  const {  useFormValidator } = useValidatorStore()

  const { initProps, dropIconState = false } = defineProps<{ initProps: InitControllerData, dropIconState?: boolean}>()

  const loading = ref(false)
  const iconState = ref('')

  const submitFormData = (event: Event) => {
    const formData = new FormData(event.target as HTMLFormElement);
    const checked = formData.has('notRobot');

    if (checked) {
      setTimeout(async () => {
        await setServiceData({ captcha: checked })
        await (() => {
          iconState.value = 'loaded'
          loading.value = false
          notificationOccurred('success')
        })()
        if (dropIconState) iconState.value = ''
      }, 2000 )

      loading.value = true
      iconState.value = 'loading'
    }
  }

  const save = (name: string) => {
    useFormValidator()

    const fieldErrors = validator.value?.errors?.[name]

    if (fieldErrors && '_errors' in fieldErrors)  {
      setNotification(fieldErrors._errors?.[0])
    } else {
      setup.value.locale[name] = form.value[name];
      useControllerStore().setServiceData({
        [name]:  setup.value.locale[name],
      }, 'save')
    }
  }

  defineExpose({ save })
</script>

<style scoped lang="scss">
  .captcha {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: end;
    height: calc(100dvh - 100px);
    #loading {
      width: 100%;
    }
    form {
      width: 100%;
    }
    .label-group {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: var(--space-half);
      label {
        margin-right: 15px;
        &:first-of-type {
          margin-right: 0;
        }
      }
      span {
        display: inline-block;
        font-weight: 600;
        cursor: pointer;
      }
    }

    label.verified {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      margin-bottom: var(--space-half);
    }
  }
</style>
