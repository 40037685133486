<template>
  <div class="message">
    <Robot v-if="setup.locale.error" />
    <IconAnim v-else :animation-name="'warning'" />
    <h1 v-html="setup.locale.message.description" />
    <ButtonCloseApp />
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'

  const { setup } = storeToRefs(useControllerStore())
</script>

<style scoped lang="scss">
  [data-theme="light"] {
    --message-text-color: #8d8d8d;
  }

  .message {
    --message-text-color: #7A7A7B;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 120px;
    height: calc(100dvh - 3rem);
    text-align: center;
    h1 {
      margin-bottom: 0;
      font-size: 1.125rem;
      font-weight: 600;
      color: var(--message-text-color);
    }
    button {
      margin-bottom: 60px;
    }
  }
</style>
