<template>
  <Form class="payments" @submit.prevent="submitFormData">
    <slot name="title" />
    <slot name="profile" />
    <slot name="general" />
    <slot name="payment_info" />
    <slot name="details" />
    <slot name="prime" />
    <slot name="payment-cards" />
    <slot name="amount" />
    <slot name="amount-cards" />
    <slot name="comment" />
    <slot name="anonymous" />
    <slot name="additional_info" />
    <ButtonGroup>
      <Button type="submit">
        {{ setup.locale.submit }}
      </Button>
    </ButtonGroup>
  </Form>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'
  import { usePaymentStore } from '@/stores/payment'
  import { useValidatorStore } from '@/stores/validator'

  const { setServiceData } = useControllerStore()
  const { setPaymentData } = usePaymentStore()
  const { setup } = storeToRefs(useControllerStore())

  const { useFormValidator } = useValidatorStore()
  const { validator, form } = storeToRefs(useValidatorStore())

  const submitFormData = () => {
    useFormValidator()

    if (validator.value.valid) {
      setPaymentData(form.value)
      setServiceData(form.value, '')
      setup.value.loaded = false
    }
  }
</script>

<style scoped lang="scss">
  .payments {
    display: flex;
    flex-direction: column;
    gap: var(--space);
    h1 {
      margin: 0;
    }
    .btn-group {
      order: 10;
      margin-top: 50px;
      width: 100%;
    }
  }
</style>
