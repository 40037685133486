<template>
  <section class="accordion">
    <div
      v-for="(item, index) in items"
      :id="item.id"
      :key="index"
      :ref="`item-${index}`"
      :class="['accordion__item', { 'active': expandedIndex === index }]"
    >
      <div class="title" @click="toggle(index)">
        {{ item.title }}
        <Icon name="arrow" />
      </div>
      <div class="description">
        <div>
          <component
            :is="componentsMap[item.component]"
            v-if="componentsMap[item.component]"
            v-bind="{ description: item.description }"
          />
          <div v-else-if="Array.isArray(item.description)">
            <Description :description="item.description" />
          </div>
          <div v-else v-html="item.description" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
  import { ref, getCurrentInstance, type Component } from 'vue';
  import ImportantPremium from '@pages/instructions/importans/ImportantPremium.vue';
  import CommandAdult from '@pages/instructions/commands/CommandAdult.vue'

  import { type AccordionArray } from '@/types/accordion'

  defineProps<{ items: AccordionArray }>();

  // @ts-expect-error proxy a global instance of refs
  const { proxy } = getCurrentInstance().appContext.app
  const expandedIndex = ref<number | null>(null);

  const toggle = (index: number) => {
    expandedIndex.value = expandedIndex.value === index ? null : index;

    const refKey = `item-${index}`;
    const element = proxy.$refs[refKey]?.[0]; // Access the DOM element

    setTimeout(() => {
      element?.scrollIntoView({ behavior: 'smooth' });
    }, 400);
  }

  const componentsMap: Record<string, Component> = {
    ImportantPremium,
    CommandAdult
  };
</script>


<style scoped lang="scss">
  .accordion {
    display: flex;
    flex-direction: column;
    gap: 2px;
    * {
      transition: all .3s ease;
    }
    .accordion__item {
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem;
        background-color: var(--bg-preset-color-20);
        border-radius: var(--radius-button);
        cursor: pointer;
      }
      > .description {
        display: grid;
        grid-template-rows: 0fr;
        padding: 0 var(--space-half);
        div {
          overflow: hidden;
        }
      }
      &.active {
        > .title svg {
          transform: rotate(180deg);
        }
        > .description {
          grid-template-rows: 1fr;
        }
      }
    }
    .accordion {
      margin-bottom: var(--space);
    }
  }
</style>
