<template>
  <div>
    <span :contenteditable="true" @input="update" @blur="onSave" @click.prevent>{{ form[props.name] }}</span>
    <button class="btn" @click.prevent="onSave">
      ✓
    </button>
    <button class="btn" @click.prevent="reset">
      ⟳
    </button>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { useValidatorStore } from '@/stores/validator';

  const { form, formDefault } = storeToRefs(useValidatorStore());

  const props = defineProps<{
    name: string;
  }>();

  const emit = defineEmits<{
    (e: 'save', name: string): void;
  }>();

  const onSave = () => {
    emit('save', props.name);
  }
  const reset = () => {
   form.value[props.name] = formDefault.value[props.name];
   emit('save', props.name);
  }
  const update = (event: any) => form.value[props.name] = event.target.textContent;
</script>

<style scoped lang="scss">
  span {
    word-wrap: break-word;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  div {
    display: block;
    white-space: pre-wrap;

    button {
      background: transparent;
      border: none;
      cursor: pointer;
      pointer-events: auto;
      margin: 0;
      padding: 0;

      &:nth-last-of-type(2) {
        color: #48f542;
      }

      &:last-of-type {
        color: #f58442;
      }
    }
  }
</style>
